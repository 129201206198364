import fetch from 'auth/FetchInterceptor'
import { ADMIN_API_BASE_ENDPOINT } from 'configs/AppConfig'
const JwtAuthService = {}

JwtAuthService.login = function (data) {

	return fetch({
		url: ADMIN_API_BASE_ENDPOINT + 'users/admin_login',
		method: 'post',
		headers: {
      		'public-request': 'true',
			'Content-Type': 'application/json'
		},
		data: data
	})
}

JwtAuthService.logout = function (data) {

	return fetch({
		url: ADMIN_API_BASE_ENDPOINT + 'users/admin_logout',
		method: 'post',
		headers: {
      		'public-request': 'true',
			'Content-Type': 'application/json'
		},
		data: data
	})
}

JwtAuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
			'public-request': 'true'
		},
			data: data
		})
}

export default JwtAuthService