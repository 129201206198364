import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, ADMIN_API_BASE_ENDPOINT } from 'configs/AppConfig'
import service from 'auth/FetchInterceptor';
import useBodyClass from 'hooks/useBodyClass';
import navigationConfig from "configs/NavigationConfig";
import history from '../history'

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  const [navigationConfigServer, setNavigation] = useState([]);
	const [adminUserData, setUserData] = useState([]);

	useEffect(() => {
		async function fetchData() {				
			await service.get(ADMIN_API_BASE_ENDPOINT + 'users/get_admin').then((response) => { 
							
				if(response.code === 400){
						
				} else {
					localStorage.setItem('userData', JSON.stringify(response.adminUserData));										
					setUserData(response.adminUserData)
					setNavigation(response.navigationConfig)		
				}		
									
			});
		}
		const jwtToken = localStorage.getItem('auth_token');
		if (jwtToken) {
			
			fetchData();
		} else {
			setNavigation(navigationConfig)		
		}			
	  }, []);

	  return navigationConfigServer.length > 0 ?

	  (
		<IntlProvider
		  locale={currentAppLocale.locale}
		  messages={currentAppLocale.messages}>
		  <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
			<Switch>
			  <Route exact path="/">
				<Redirect to={APP_PREFIX_PATH} />
			  </Route>
			  <Route path={AUTH_PREFIX_PATH}>
				<AuthLayout direction={direction} />
			  </Route>
			  <Route path={APP_PREFIX_PATH}>
				<AppLayout direction={direction} location='/app' adminUserData={adminUserData} navigationConfig={navigationConfigServer}/>
			  </Route>
			</Switch>
		  </ConfigProvider>
		</IntlProvider>
	  ) : 

	  (
		<IntlProvider
		  locale={currentAppLocale.locale}
		  messages={currentAppLocale.messages}>
		  <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
			<Switch>			 
			  <Route path={AUTH_PREFIX_PATH}>
				<AuthLayout direction={direction} />
			  </Route>			  
			</Switch>
		  </ConfigProvider>
		</IntlProvider>
	  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token, userData } = auth;
  return { locale, token, userData, direction }
};

export default withRouter(connect(mapStateToProps)(Views));