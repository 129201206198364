import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { Link } from 'react-router-dom';

const menuItem = [
  // {
	// 	title: "Admin Setting",
	// 	icon: SettingOutlined,
	// 	path: "/settings"
  // } 
]

export const NavProfile = ({userData , signOut}) => {
  const userAccessData = userData && Object.keys(userData).length > 0 ? userData : [];
	
  const profileImg = "/img/logo-icon.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{userAccessData.full_name}</h4>
            <span className="text-muted">{userAccessData.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>          
              <Link to='/auth/login/'>
                <LogoutOutlined />
                <span className="font-weight-normal">Sign Out</span>
              </Link >
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}


const mapStateToProps = ({auth}) => {
	const {token, userData} = auth;
  	return {token, userData}
}

export default connect(mapStateToProps, {signOut})(NavProfile)
