import {
    DashboardOutlined,
    UserAddOutlined,
    UserOutlined,
    CarOutlined,
    GroupOutlined,
    BarsOutlined,
    ProfileOutlined,
    HomeOutlined,
    ShopOutlined,
    FileImageOutlined,
    ShoppingCartOutlined,
    AuditOutlined,
    MessageOutlined,
    IssuesCloseOutlined,
    GiftOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
    {
        key: 'main-sidebar',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'Dashboards',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'home',
                path: `${APP_PREFIX_PATH}/home`,
                title: 'Dashboard',
                icon: DashboardOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'company_management',
                path: `${APP_PREFIX_PATH}/company/lists`,
                title: 'Company List',
                icon: AuditOutlined,
                breadcrumb: false,
                submenu: []
            },
            {
                key: 'ticket_management',
                path: `${APP_PREFIX_PATH}/ticket/lists`,
                title: 'Tickets',
                icon: MessageOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        key: 'sales_ticket',
                        path: `${APP_PREFIX_PATH}/ticket/sale`,
                        title: 'Sales Ticket',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'maintenance_ticket',
                        path: `${APP_PREFIX_PATH}/ticket/maintenance`,
                        title: 'Maintenance Ticket',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'coaching_ticket',
                        path: `${APP_PREFIX_PATH}/ticket/coaching`,
                        title: 'Coaching Ticket',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },
            {
                key: 'device_management',
                path: `${APP_PREFIX_PATH}/company/lists`,
                title: 'Device',
                icon: GroupOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        key: 'add_device',
                        path: `${APP_PREFIX_PATH}/device/lists`,
                        title: 'Device List',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },
            {
                key: 'order_management',
                path: `${APP_PREFIX_PATH}/order/company`,
                title: 'Orders',
                icon: ShoppingCartOutlined,
                breadcrumb: false,
                submenu: [
                    {
                        key: 'company_orders',
                        path: `${APP_PREFIX_PATH}/order/company`,
                        title: 'Company Orders',
                        breadcrumb: false,
                        submenu: []
                    },
                    {
                        key: 'device_orders',
                        path: `${APP_PREFIX_PATH}/order/device`,
                        title: 'Device Orders',
                        breadcrumb: false,
                        submenu: []
                    },
                ]
            },
        ]
    }
]

const navigationConfig = [
    ...dashBoardNavTree
]

export default navigationConfig;
