import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Menu, Grid, Badge } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import * as AntdIcons from '@ant-design/icons';
import { ADMIN_API_BASE_ENDPOINT } from 'configs/AppConfig';
import service from 'auth/FetchInterceptor';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, socket, hideGroupTitle, localization, onMobileNavToggle, userData, navigationConfig } = props;
	// console.log('SideNavContent')
	// console.log(navigationConfig)
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}


	const [totalReminder, setTotalReminder] = useState(0);
	const [totalStaffReminder, setTotalStaffReminder] = useState(0);
	const [totalSimReminder, setTotalSimReminder] = useState(0);
	const [totalLeadCount, setTotalLeadCount] = useState(0);
	const [totalTicketCount, setTotalTicketCount] = useState(0);
	const [salesTicketCount, setSalesTicketCount] = useState(0);
	const [maintenanceTicketCount, setMaintenanceTicketCount] = useState(0);
	const [coachingTicketCount, setCoachingTicketCount] = useState(0);

	const getTotalReminder = () => {
		service.post(ADMIN_API_BASE_ENDPOINT + 'users/total_reminder').then(response => {
			setTotalReminder(response.sim_reminder);
			setTotalStaffReminder(response.staff_reminder);
			setTotalSimReminder(response.sim_reminder);
		});
	}

	const getLeadCount = () =>{
		service.post(ADMIN_API_BASE_ENDPOINT + 'leads/total_count').then(response => {
			setTotalLeadCount(response.total_lead);
		});
	}

	const getTicketCount = () =>{
		service.post(ADMIN_API_BASE_ENDPOINT + 'ticket/total_count').then(response => {
			setTotalTicketCount(response.total_ticket);
			setSalesTicketCount(response.sales_ticket);
			setMaintenanceTicketCount(response.maintenance_ticket);
			setCoachingTicketCount(response.coaching_ticket);
		});
	}

	

	useEffect(() => {
		getLeadCount();
		getTotalReminder();
		getTicketCount();

	}, []);

	return (
		<Menu
			theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
			mode="inline"
			style={{ height: "100%", borderRight: 0 }}
			defaultSelectedKeys={[routeInfo?.key]}
			defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
			className={hideGroupTitle ? "hide-group-title" : ""}
		>
			{navigationConfig.map((menu) => {
				const AntdParentIcon = AntdIcons[menu.icon];					
				return (
					menu.submenu.length > 0 ? (
						<Menu.ItemGroup
							key={menu.key}
							title={menu.title}
						>
							{
								menu.submenu.map((subMenuFirst) => {
									const AntdIcon = AntdIcons[subMenuFirst.icon];
									
									
									return (
										subMenuFirst.show === true && subMenuFirst.submenu.length > 0 ? (
											<SubMenu
												icon={
													subMenuFirst.icon ? (
														<AntdIcon />
													) : null
												}
												key={subMenuFirst.key}
												title={<>
													{subMenuFirst.title}
													<div style={{ float: "right" }}>
														 																																							
														  {
															  subMenuFirst.key === 'all_reminders' &&
															  <span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalReminder > 0 ? 'block' : 'none' }}>
																  <sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																	  <span className="ant-scroll-number-only">
																		  <span className="ant-scroll-number-only-unit current">{totalReminder}</span>
																	  </span>
																  </sup>
															  </span>
														  }																					
														  {
															  subMenuFirst.key === 'ticket_management' &&
															  <span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalTicketCount > 0 ? 'block' : 'none' }}>
																  <sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																	  <span className="ant-scroll-number-only">
																		  <span className="ant-scroll-number-only-unit current">{totalTicketCount}</span>
																	  </span>
																  </sup>
															  </span>
														  }																					
														  {
															  subMenuFirst.key === 'manage_staff' &&
															  <span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalStaffReminder > 0 ? 'block' : 'none' }}>
																  <sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																	  <span className="ant-scroll-number-only">
																		  <span className="ant-scroll-number-only-unit current">{totalStaffReminder}</span>
																	  </span>
																  </sup>
															  </span>
														  }																					
													  </div>
												  </>}
												danger={true}
											>
												
												{
													subMenuFirst.submenu.map((subMenuSecond) => {
														const AntdSubIcon = AntdIcons[subMenuSecond.icon];
														return (
															subMenuSecond.show === true ?
																<Menu.Item key={subMenuSecond.key}>
																	{subMenuSecond.icon ? (
																		<AntdSubIcon />
																	) : null}
																	<span> 
																		{subMenuSecond.title} 
																		<div style={{ float: "right" }}>
																		{
																			subMenuSecond.key === 'staff_reminders' &&
																			<span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalStaffReminder > 0 ? 'block' : 'none' }}>
																				<sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																					<span className="ant-scroll-number-only">
																						<span className="ant-scroll-number-only-unit current">{totalStaffReminder}</span>
																					</span>
																				</sup>
																			</span>
																		}
																		{
																			subMenuSecond.key === 'sim_reminders' &&
																			<span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalSimReminder > 0 ? 'block' : 'none' }}>
																				<sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																					<span className="ant-scroll-number-only">
																						<span className="ant-scroll-number-only-unit current">{totalSimReminder}</span>
																					</span>
																				</sup>
																			</span>
																		}
																		{
																			subMenuSecond.key === 'sales_ticket' &&
																			<span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': salesTicketCount > 0 ? 'block' : 'none' }}>
																				<sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																					<span className="ant-scroll-number-only">
																						<span className="ant-scroll-number-only-unit current">{salesTicketCount}</span>
																					</span>
																				</sup>
																			</span>
																		}
																		{
																			subMenuSecond.key === 'maintenance_ticket' &&
																			<span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': maintenanceTicketCount > 0 ? 'block' : 'none' }}>
																				<sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																					<span className="ant-scroll-number-only">
																						<span className="ant-scroll-number-only-unit current">{maintenanceTicketCount}</span>
																					</span>
																				</sup>
																			</span>
																		}
																		{
																			subMenuSecond.key === 'coaching_ticket' &&
																			<span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': coachingTicketCount > 0 ? 'block' : 'none' }}>
																				<sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																					<span className="ant-scroll-number-only">
																						<span className="ant-scroll-number-only-unit current">{coachingTicketCount}</span>
																					</span>
																				</sup>
																			</span>
																		}
																		</div>
																	</span>	
																	<Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />																
																</Menu.Item> : null
														)
													}
													)
												}
											</SubMenu>
										) : (
											subMenuFirst.show === true ?
												<Menu.Item key={subMenuFirst.key}>

													{subMenuFirst.icon ? <AntdIcon /> : null}
													<span>{subMenuFirst.title}
														<div style={{ float: "right" }}>
														  {
															  subMenuFirst.key === 'lead_management' &&
															  
															  <span className="ant-badge ant-badge-not-a-wrapper mt-2" style={{ 'display': totalLeadCount > 0 ? 'block' : 'none' }}>
																  <sup data-show="true" className="ant-scroll-number ant-badge-count" title="7">
																	  <span className="ant-scroll-number-only">
																		  <span className="ant-scroll-number-only-unit current">{totalLeadCount}</span>
																	  </span>
																  </sup>
															  </span>
														  }
														</div>			
													</span>													
													<Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />

												</Menu.Item>
												: null
										)
									)
								}
								)}
						</Menu.ItemGroup>
					) : (
						<Menu.Item key={menu.key}>
							{menu.icon ? <AntdParentIcon /> : null}
							<span>{setLocale(localization, menu?.title)}</span>
							{menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
						</Menu.Item>
					)
				)
			}
			)}
		</Menu>
	);
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.navigationConfig !== undefined && props.navigationConfig.length > 0 && props.type === NAV_TYPE_SIDE ? (
		<SideNavContent {...props} navigationConfig={props.navigationConfig} />
	) : (
		<></>
	);
};

const mapStateToProps = ({ theme, auth }) => {
  const { sideNavTheme, topNavColor } = theme;
  const { userData } = auth;
  return { sideNavTheme, topNavColor, userData };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
