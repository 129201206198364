import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Cloud Logistics';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const ADMIN_API_BASE_ENDPOINT = API_BASE_URL + 'api/';
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const PER_PAGE_DATA = 100;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const ONLY_DATE_FORMAT = 'YYYY-MM-DD';
export const ONLY_TIME_FORMAT = 'HH:mm';
export const GOOGLE_MAP_KEY = 'AIzaSyDnOQS1VsTVqknhTyvaWgk-U0BOE2KahhI';
export const AWS_S3_URL = 'https://dui7sst88kk80.cloudfront.net/';
export const SOCKET_URL = env.SOCKET_URL;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
